@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

body{
    background: #eaecee;
}

.wrapper{
    display: flex;
    position: relative;
}
.wrapper .sidebar{
    position: fixed;
    width: 200px;
    height: 100%;
    background: #1c2833;
    padding: 30px 0;
}

.wrapper .sidebar h2 {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
}

.wrapper .sidebar ul li {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    border-top: 1px solid rgba(225,225, 225, 0.05);
}

.wrapper .sidebar ul li a {
    color: #fff;
    display: block;
    font: menu;
    text-align:left;
}

.social_app img {
    width: 100%;
    height: auto;
}

.wrapper .sidebar ul li a .fas {
    width: 25px;
}

.wrapper .sidebar ul li:hover {
    background: #17202a;
}

.wrapper .sidebar ul li:hover a{
    color: #fff;
}

.wrapper .sidebar .social_app {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.wrapper .sidebar .social_app a{
    display: block;
    width: 60px;
    background: #ffffff;
    height: 60px;
    line-height: 40px;
    text-align: center;
    margin: 0 5px;
    color: #eaecee;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.wrapper .main_content {
    width: 100%;
    margin-left: 200px;
}

.wrapper .main_content .header {
    padding: 20px;
    background: #fff;
    color: #717171;
    border-bottom: 1px solid #e0e4e8;
}

.wrapper .main_content .info {
    margin: 20px;
    color: #717171;
    line-height: 25px;
}

.wrapper .main_content .info div{
    margin-bottom: 20px;
}



.main_content .header_content {
    margin-top: 5px;
    display: flex;
    padding: 10px;
}

.header_content {

    padding-top: 10px;
    text-align: left;
    text-transform: uppercase;
    height: 40px;
}

.header_content h1 {
    color: rgb(182, 182, 182);
    font-size: 25px;
    padding-left: 10px;
    font-style: normal;
}

.graph {
    position: relative;
    width: 100%;
    height: 100px;
    padding-right: 7.5px;
    padding-right: 7.5px;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px 1px rgba(124, 124, 124, 0.09);
    background: #fff;
    border-radius: 5px;
    margin: 5px;
}

.graph h1 {
    font-size: 15px;
    font-style: normal;
    padding-left: 10px;
}

.d-flex {
    display: flex;
    box-sizing: border-box;
    padding-left: 20px;
}

span {
    font-size: 15px;
}


.btn-primary {
    cursor: pointer;
    background-color: #5ac146;
    border-color: #5ac146;
}
.btn-delete {
    cursor: pointer;
    background-color: #c8371e;
    border-color: #c8371e;
}

.btn-close {
    cursor: pointer;
    background-color: #f5c661;
    border-color: #f5c661;
}


.btn-option {
    cursor: pointer;
    background-color: #fe9d6a;
    border-color: #fe9d6a;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 3px;
}

.text-white {
    color: #fff !important;
    font-size: small;
}

button {
    margin: 0;
    text-transform: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


.form-select {
    flex: 0 0 auto;
    width: 100%;
    color: #4f5467;
    height: 40px;
    display: block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    appearance: none;
}

.shadow-none {
    box-shadow: none !important;
}

.input {

    flex: 0 0 auto;
    width: 100%;
    color: #4f5467;
    height: 40px;
    display: block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    appearance: none;
    box-shadow: none !important;
}

.table-data {
    overflow-x: auto;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
    display: table;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #222222;
    vertical-align: top;
    border-color: rgba(0, 0, 0, 0.1);
    font:-webkit-small-control;
    background:#f7f7f7;
}

tbody , thead , tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-bottom: 1px solid #fff;
    border-color:#c9c9c9;
    text-align: center;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}



tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: initial center;
}

.table>thead{
    vertical-align: bottom;
    background:#e7e4e4;
}
.table>:not(:last-child)>:last-child>*{
    border-bottom-color: #e9ecef;
}
.table th , .table thead th {
    font-weight: 500;
}

.table thead th {
    color: #000000;
    font-size: small;
}

.table td , .table th {
    padding: 1rem;
}

.table>tbody {
    vertical-align: initial;
}
tr:nth-child(even) {

    background-color:rgb(255 255 255);
}
footer {
    display: block;
}
.footer {
    padding: 15px 20px;
    font-size: 15px;
    color: #717171;
}

.text-center {
    text-align: center !important;
}


.pagination {
    display: block;
}

.pagination ul li {
    color: #717171;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
}

.pagination ul li:active{
    background-color: #5ac146;
    color: white;
    border-radius: 5px;
}

.pagination ul li :hover:not(.active) {
    background-color: #ddd;
    border-radius: 5px;
}

.controls-item {
    display: inline-block;
}

.btn {
    margin: 1px;
}