.list-container-card {

    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 50px;
}


.listCard {

    background: #1c2833;
    color: #fff;
    margin: 20px;
    border-radius: 20px;
    width: 30rem;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 30%);
}



.card {

    margin: 5px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
}

.container-card {

    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    margin: 2px;
    justify-items: stretch;
    background-color: #1f364a;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px

}


.sp-card {

    margin: 10px;
    font-size: small;

}

.sp-card-text {
    font-size: small;
}

.text-red {
    color: #ff0000;
}

.text-org {
    color: #ffa500;
}


.listCard-game{

    background: #1c2833;
    color: #fff;
    margin: 20px;
    border-radius: 20px;
    width: 15rem;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 30%);
}

.sp-card-games {

    margin: 10px;
    font-size: small;

}


.listCard-product {

    background: #fff;
    color: #383838;
    margin: 20px;
    border-radius: 20px;
    width: 16rem;
    box-shadow: 0 0px 0 0 rgb(0 0 0 / 30%);
    cursor:default;
}

.container-card-product {

    background-color: #fff;
    height: 20rem;

}

.img-product  img{

    border-radius: 20px;
    width: 142px;
    height: 196px;
    margin-top: 10px;

}

.img-product .shadow-img {

    flex: 1;
    height: 15px;
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.15) 100%)
}

.pd {

    padding-top: 5px;
    text-align: left;
}

.pd-name {
    text-align: start;
}

.pd-price {

    color: #f57224;
    display: flex;
    justify-content: space-between;
}

.pd-sold {

    text-align: right;
    color: #afafaf;
    padding-bottom: 10px
}

.product {

    align-items: stretch;
}

.img-card img{
   width: fit-content;
}

.img-offset.img-product.img-card {
    height: 250px;
}