.LoginForm {

    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(320deg, #00956c, #4c8f89cf);
}

.form-SignIn {
    height: 35rem;
}


.text-header-login {

    color: #868686;

}

.box-data-login {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    width: 25rem;
    height: 100%;
    background: #ededed;
    left: 0;
    top: 0;
    margin: auto;
    border-radius: 20px;
    text-align: start;
    padding: 10px;
}

.form-input-login {

    width: 100%;
    margin: 0;
    padding-top: 10px;
    display: grid;
    justify-items: start;
    color: #fff;

}

.form-input-login-btn {

    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 10px;
}

.input-form {

    display: flex;
    flex-direction: column;
    padding-left: 40px;

}

.input-form span {
    color: #00a1ff;
    font-size: 12px;
}

h1 {

    font-size: 20px;
}

.btn-Login {

    color: #fff;
    width: 200px;
    border-radius: 30px;
    background: #00d58d;

}

.input-SignIn {

    background-color:transparent;
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    outline: 0;
    color: #868686;
}

.login-descripts {
    color: #7a7a7a;
    font-size: 12px;
    padding-left: 50px;
}